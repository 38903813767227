<template>
    <section class="content-wrapper">
        <section class="content-header">
            <h1>
                Subscription Plans
            </h1>
            <ol class="breadcrumb">
                <!-- <li class="breadcrumb-item"><a href="#"><i class="iconsmind-Library"></i></a></li>
                <li class="breadcrumb-item"><a href="#">Examples</a></li>
                <li class="breadcrumb-item active">Contact</li> -->
            </ol>
        </section>
        <section class="content">
            <div class="row">
                <div v-for="subscription in subscriptionList" :key="subscription.id" class="col-6 col-md-6 col-lg-4 col-xl-3">
                    <router-link :to="{path: `/view-subscription/${subscription.id}`}">
                        <div class="box box-body">
                            <div class="flexbox align-items-center">
                                <label class="toggler toggler-yellow">
                                    <input type="checkbox" disabled checked>
                                    <i class="fa fa-star"></i>
                                </label>
                                <!-- <div class="dropdown">
                                    <a data-toggle="dropdown" href="#" aria-expanded="false"><i class="ion-android-more-vertical"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <a class="dropdown-item" href="#"><i class="fa fa-fw fa-user"></i> Profile</a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-fw fa-comments"></i> Messages</a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-fw fa-phone"></i> Call</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" href="#"><i class="fa fa-fw fa-remove"></i> Remove</a>
                                    </div>
                                </div> -->
                            </div>
                            <div class="text-center pt-3">
                                <!-- <a href="#">
                                    <img class="avatar avatar-xxl" src="assets/images/avatar/1.jpg" alt="">
                                </a> -->
                                <h5 class="mt-15 mb-0"><a href="#">{{subscription.name}}</a></h5>
                                <span>{{ subscription.validity }} days validity</span>
                            </div>
                            <div class="flexbox justify-content-center mt-30">
                                <button class="btn">{{subscription.order_limit}} Orders</button>
                                <button class="btn">₦{{subscription.subscription_amount_formatted}}</button>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import { repository } from '../../presenter';
export default {
    data(){
        return {
            subscriptionList: [],
        }
    },
    mounted(){
        this.loadSubscriptions();
    },
    methods: {
        async loadSubscriptions() {
            let subscriptionResponse = await repository.subscription.fetchSubscriptions();
            if (subscriptionResponse.success){
                this.subscriptionList = subscriptionResponse.data;
                return;
            }
        }
    }
}
</script>

